<template>
  <div v-if="page_loading">
    <Loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <Loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ใบส่งสินค้า
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
        <v-spacer/>
        <v-btn :elevation="1" color="primary" class="pa-2" @click="UpdateDeliveryNote(0)">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          เพิ่มรายการใหม่
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                วันที่ :
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateStart"
                      label="เริ่มต้น"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      @blur="startDate = parseDate(dateStart)"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="dateStart = null"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" @change="dateStart=formatDate(startDate)" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateEnd"
                      label="สิ้นสุด"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      @blur="endDate = parseDate(dateEnd)"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="dateEnd = null"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" @change="dateEnd=formatDate(endDate)" no-title scrollable :allowed-dates="allowedDates">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                เลขที่บิล :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="search.masterId"
                  @change="value => search.masterId = Trim_value(value)"
                  @keyup.enter="SearchStock()"
                  outlined
                  dense hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" @click="SearchData(limits,0,search,1)">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pl-1">
        ทั้งหมด {{ formatMoney(total) }} แถว
      </v-col>
    </v-row>

    <!-- แสดงตาราง -->
    <v-card elevation="1">
      <v-card-text class="pa-0">

        <v-data-table
          :headers="header"
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-footer
          :mobile-breakpoint="0"
          class="packhai-border-table"
        >
          <template v-slot:item.no="{ item }">
            {{ formatMoney((total-dataset.indexOf(item))-offsets) }}
          </template>
          <template v-slot:item.delete="{ item }">
              <a @click="Delete(item.id)"><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a>
          </template>
          <template v-slot:item.print="{ item }">
              <a @click="Print(item.id)"><v-icon size="18" color="info darken-3">fa-solid fa-print</v-icon></a>
          </template>
          <template v-slot:item.id="{ item }">
            <a @click="UpdateDeliveryNote(item.id)"> {{ GenDN(item.id) }} </a>
          </template>
          <template v-slot:item.created="{ item }">
            <span class="fn-12"> {{ formatDatetime(item.created) }} </span>
          </template>
          <template v-slot:item.quantity="{ item }">
            <span class="fn-12"> {{ formatMoney(item.quantity) }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- เปลี่ยนหน้า -->
    <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2 ma-0" @click="changePage()">
      <v-col cols="12" class="pa-0">
        <v-pagination
          v-model="page"
          :length="pageLength"
          :total-visible="pageTotalVisible"
          color="error"
        ></v-pagination>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { branchService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertError } from '@/website/global_alert'
  import { formatDatetime, formatDate, GetDate, parseDate, allowedDates, GenDN, ChangeIE, Trim_value, formatMoney } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: vm => ({
      // loading
      page_loading: true,
      loading: false,

      // วันที่
      startDate: null,
      dateStart: null,
      menuStartDate: false,
      checkStart: true,
      endDate: null,
      dateEnd: null,
      menuEndDate: false,
      checkEnd: true,

      // ค้นหา
      search: {
        startDate: null,
        endDate: null,
        remark: null,
        masterId: null,
      },

      // แสดงตาราง
      total: 0,
      dataset: [],
      header: [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px' },
        { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px'},
        { text: 'ปริ้น',align: 'center',sortable: false,value: 'print', width: '60px'},
        { text: 'เลขที่บิล',align: 'center',sortable: false,value: 'id', width: '90px'},
        { text: 'วันเวลา',align: 'center',sortable: false,value: 'created', width: '150px'},
        { text: 'จำนวนพัสดุ',align: 'right',sortable: false,value: 'quantity', width: '80px'},
        { text: 'พนักงาน',align: 'left',sortable: false,value: 'createdByName'},
        { text: 'ชื่อคนขับ',align: 'left',sortable: false,value: 'driverName'},
        { text: 'เบอร์โทร',align: 'center',sortable: false,value: 'phone', width: '130px'},
        { text: 'เลขทะเบียน',align: 'center',sortable: false,value: 'carPlate', width: '120px'},
        { text: 'หมายเหตุ',align: 'left',sortable: false,value: 'remark'},
      ],

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 50,
      offsets: 0,

      // ขนาดจอ
      Window: localStorage.getItem('Window'),
      PageSize: localStorage.getItem('PageSize'),
      PageWidth: parseInt(localStorage.getItem('PageWidth')),
      PageHeight: parseInt(localStorage.getItem('PageHeight')),

    }),
    watch: {
      startDate: async function(newVal, oldVal) {
        if (new Date(newVal)>new Date(this.endDate) && this.endDate!=null && newVal!=null ){
          if (oldVal!=null){
            this.checkStart = false
            this.startDate = oldVal
          }else{
            this.dateStart = oldVal
            this.$refs.menuStartDate.save(oldVal)
          }
        }else if(this.checkStart){
          try {
            this.$refs.menuStartDate.save(newVal)
          }catch(err) {
            console.log("");
          }
        }else{
          this.dateStart = this.formatDate(newVal)
          this.$refs.menuStartDate.save(newVal)
          this.checkStart = true
        }
      },
      endDate: async function(newVal, oldVal) {
        if (new Date(newVal)<new Date(this.startDate) && this.startDate!=null  && newVal!=null){
          if (oldVal!=null){
            this.checkEnd = false
            this.endDate = oldVal
          }else{
            this.dateEnd = oldVal
            this.$refs.menuEndDate.save(oldVal)
          }
        }else if(this.checkEnd){
          try {
            this.$refs.menuEndDate.save(newVal)
          }catch(err) {
            console.log("");
          }
        }else{
          this.dateEnd = this.formatDate(newVal)
          this.$refs.menuEndDate.save(newVal)
          this.checkEnd = true
        }
      },
    },
    async created() {
      this.dateStart = this.GetDate()
      this.startDate = this.parseDate(this.dateStart)
      this.dateEnd = this.dateStart
      this.endDate = this.startDate
      await this.SearchData(this.limits,0,this.search,1)
      if (this.Window=="mobile"){
        this.header = [
          { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px' },
          { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px'},
          { text: 'ปริ้น',align: 'center',sortable: false,value: 'print', width: '60px'},
          { text: 'เลขที่บิล',align: 'center',sortable: false,value: 'id', width: '90px'},
          { text: 'วันเวลา',align: 'center',sortable: false,value: 'created', width: '140px'},
          { text: 'จำนวน',align: 'right',sortable: false,value: 'quantity', width: '80px'},
          { text: 'พนักงาน',align: 'left',sortable: false,value: 'createdByName'},
          // { text: 'ชื่อคนขับ',align: 'left',sortable: false,value: 'driverName'},
          // { text: 'เบอร์โทร',align: 'center',sortable: false,value: 'phone', width: '130px'},
          // { text: 'เลขทะเบียน',align: 'center',sortable: false,value: 'carPlate', width: '120px'},
          { text: 'หมายเหตุ',align: 'left',sortable: false,value: 'remark'},
        ]
      }
      this.page_loading = false
    },
    methods: {
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          await this.SearchData(this.limits,offsets,this.search,this.page)
          this.pageTemp = this.page
        }
      },
      async UpdateDeliveryNote(Id){
        window.open("/delivery-note/detail?id="+btoa(Id));
      },
      async Print(Id){
        window.open("/delivery-note/print?id="+btoa(Id));
      },
      async Delete(Id){
        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ลบ ใช่หรือไม่',
          text: 'เลขที่บิล '+this.GenDN(Id),
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(branchService_dotnet+'Branch/delete-delivery-note', {
              "masterId": Id,
            }, {headers: header_token})
            if (response.status==200) {
              await this.SearchData(this.limits,0,this.search,1)
              this.loading = false
              this.AlertSuccess()
            }else{
              this.loading = false
              this.AlertError()
            }
           }
        })
      },
      async SearchData(limits,offsets,search,page){
        this.loading = true
        var ie = this.ChangeIE(search.masterId)
        let response = await axios.post(branchService_dotnet+'Branch/get-delivery-note', {
          "branchId": parseInt(localStorage.getItem('Branch_BranchID')),
          "startDate": this.startDate==null ? null : new Date(this.startDate+" 07:00:00").toISOString(),
          "endDate": this.endDate==null ? null : new Date(this.endDate+" 07:00:00").toISOString(),
          "masterId": ie=='' ? null : ie,
          "skip": offsets,
          "take": limits,
        }, {headers: header_token})
        this.loading = false
        if (response.status==200){
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.dataset = response.data.resultData
          this.total = response.data.resultCount
          this.pageLength =  (Math.ceil(this.total/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
        } else {
          this.AlertError()
        }
      },
      formatDatetime,
      formatDate,
      parseDate,
      allowedDates,
      GenDN,
      ChangeIE,
      Trim_value,
      GetDate,
      formatMoney,
      AlertSuccess,
      AlertError,
    }
  }
</script>
